<template lang="pug">
  .book-item.c-pointer( @click="goDetail")
    img(:src="bookItem.top_banner_img")
    .name {{bookItem.goods_name}}
    .describe {{bookItem.describe}}
    .price 售价：
      span.red ¥{{bookItem.goods_price}}
    .commission 赚取：¥{{bookItem.commission_price}}
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            bookItem: {}
        };
    },
    mounted() {
        this.bookItem = this.item;
    },
    methods: {
        goDetail() {
            this.$emit("goDetail", this.item.id);
        }
    }
};
</script>

<style lang="less">
.book-item {
    width: 280px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 14px;
    color: #333;
    line-height: 1;
    text-align: left;
    img {
        box-sizing: border-box;
        width: 100%;
        height: 153px;
        margin-bottom: 8px;
        border-radius: 5px;
    }
    .name,
    .describe {
        box-sizing: border-box;
        width: 100%;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        padding: 0 16px;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .describe {
        font-size: 13px;
        color: #888;
    }
    .price {
        float: left;
        min-width: 100px;
        height: 32px;
        line-height: 32px;
        margin-left: 16px;
        overflow: hidden;
        vertical-align: middle;
        .red {
            color: #fb3e31;
        }
    }
    .commission {
        float: right;
        width: 120px;
        height: 32px;
        line-height: 32px;
        margin-right: 16px;
        background: linear-gradient(
            135deg,
            rgba(81, 123, 252, 1) 0%,
            rgba(81, 123, 252, 1) 100%
        );
        color: #fff;
        border-radius: 4px;
        text-align: center;
        vertical-align: middle;
    }
}
.book-item:hover {
    box-shadow: 0px 2px 4px 0px rgba(42, 103, 255, 0.3);
}
</style>
