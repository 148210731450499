<template>
    <div class="category-list-page">
        <div class="pos-fixed">
            <top-bar></top-bar>
            <first-head></first-head>
        </div>
        <div class="pt-top-head-height">
            <div
                class="advertising"
                @click="handleLink(topImg.linkurl)"
                v-if="routeName == 'product'"
                v-show="show"
            >
                <img :src="topImg.imageurl" :alt="topImg.title" />
                <div class="close-div" @click.stop="show = false">
                    <img
                        class="close"
                        src="@/assets/images/close.png"
                        alt="关闭"
                    />
                </div>
            </div>
            <div class="content">
                <template v-if="routeName == 'searchResult'">
                    <div class="title-text">“{{ keywords }}”相关内容</div>
                </template>
                <template
                    v-if="routeName == 'category' || routeName == 'product'"
                >
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
                        <el-breadcrumb-item>{{
                            routeName == "category" ? "单品推广" : "产品组合"
                        }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </template>
                <template v-if="routeName == 'categories'">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
                        <el-breadcrumb-item>全部分类</el-breadcrumb-item>
                        <el-breadcrumb-item>{{
                            categoryTitle
                        }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </template>
                <template v-if="courseData.length > 0">
                    <el-table
                        class="sort-table"
                        style="width: 412px"
                        @sort-change="changeSort"
                    >
                        <el-table-column width="96" align="center">
                            <template slot="header">
                                <div class="active">最新</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="commission_price"
                            label="佣金"
                            sortable="custom"
                            width="108"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="goods_price"
                            label="价格"
                            sortable="custom"
                            width="108"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="order_count"
                            label="销量"
                            sortable="custom"
                            width="108"
                            align="center"
                        ></el-table-column>
                    </el-table>
                    <div class="course-list">
                        <course-item
                            v-for="item in courseData"
                            :key="item.id"
                            :item="item"
                            @goDetail="goDetail"
                        ></course-item
                        ><i v-for="n in courseData.length" :key="n"></i>
                    </div>
                    <Pagination
                        :currentPage.sync="pagination.page"
                        :pageSize="pagination.perpage"
                        :total="pagination.count"
                        :sumPage="pagination.page_total"
                    ></Pagination>
                </template>
                <div class="no-course" v-else>
                    <img src="@/assets/images/empty.png" />
                    <div class="tips">暂无相关内容</div>
                </div>
            </div>
        </div>
        <bottomFoot></bottomFoot>
    </div>
</template>
<script>
import { getProduct, getProducts } from "@/api/product.js";
import { getPoster } from "@/api/index.js";
import Pagination from "@/components/pagination";
import courseItem from "@/components-biz/course-item.vue";
export default {
    components: { Pagination, courseItem },
    data() {
        return {
            show: true,
            topImg: {},
            routeName: "",
            categoryId: null,
            categoryTitle: null,
            keywords: "",
            sort_way: "",
            commission_price: "",
            goods_price: "",
            order_count: "",
            courseData: [],
            pagination: {
                page: 1,
                perpage: 10,
                count: 10,
                page_total: 0
            }
        };
    },
    mounted() {
        this.refreshCategoryId();
    },
    watch: {
        $route(v) {
            this.refreshCategoryId();
        },
        "pagination.page"(v) {
            this.getData();
        }
    },
    methods: {
        refreshCategoryId() {
            this.routeName = this.$route.name;
            this.categoryId = this.$route.query.id;
            this.categoryTitle = this.$route.query.title;
            this.keywords = this.$route.query.keywords;
            this.sort_way = "";
            this.pagination.page = 1;
            this.getData();
            if (this.$route.name == "product") {
                this.getPoster();
            }
        },
        getPoster() {
            getPoster(4).then(res => {
                if (res.code == 0) {
                    this.topImg = res.data[0];
                }
            });
        },
        getData() {
            let data = {
                type: this.categoryId,
                keyword: this.keywords,
                page: this.pagination.page,
                sort: null
            };
            let sw = this.sort_way;
            if (sw != "") {
                let st;
                if (this[sw] == "ascending") {
                    st = "asc";
                } else if (this[sw] == "descending") {
                    st = "desc";
                }
                data.sort = sw + "-" + st;
            }

            if (
                this.routeName == "category" ||
                this.routeName == "categories" ||
                this.routeName == "searchResult"
            ) {
                //单品
                getProduct(data).then(res => {
                    if (res.code == 0) {
                        this.courseData = res.data.list;
                        this.pagination = res.data.paginate;
                    }
                });
            } else {
                //组合
                getProducts(data).then(res => {
                    if (res.code == 0) {
                        this.courseData = res.data.list;
                        this.pagination = res.data.paginate;
                    }
                });
            }
        },
        changeSort(val) {
            this.resetSort();
            this[val.prop] = val.order;
            this.sort_way = val.prop;
            this.getData();
        },
        resetSort() {
            this.commission_price = "";
            this.goods_price = "";
            this.order_count = "";
            this.pagination.page = 1;
        },
        goDetail(val) {
            let type =
                this.routeName == "category" ||
                this.routeName == "categories" ||
                this.routeName == "searchResult"
                    ? 0
                    : 1;
            this.$router.push({
                name: "courseDetail",
                query: { id: val, type: type }
            });
        },
        handleLink(url) {
            if (url) window.open(url, "_blank");
        }
    }
};
</script>

<style lang="less">
.category-list-page {
    .advertising {
        width: 1200px;
        height: 110px;
        margin: 30px auto;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            display: block;
        }
        .close-div {
            width: 30px;
            height: 30px;
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            right: 10px;
            top: 10px;
            border-radius: 50%;
            transition: background 0.5s ease;
            .close {
                width: 10px;
                height: 10px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .close-div:hover {
            background: rgba(0, 0, 0, 0.9);
        }
    }
    .content {
        width: 1200px;
        margin: 30px auto;
        .title-text {
            font-size: 16px;
            color: #000;
            font-weight: 500;
            line-height: 25px;
            margin-bottom: 20px;
        }
        .el-breadcrumb {
            color: #000;
            font-size: 16px;
            padding-bottom: 12px;
            border-bottom: 1px solid #f1f1f1;
        }
        .sort-table {
            margin: 25px 0 30px;
            .el-table__body-wrapper {
                display: none;
            }
            .el-table__header-wrapper {
                border-radius: 5px;
                tr {
                    height: 40px;
                    overflow: hidden;
                    th {
                        position: relative;
                        border: none;
                        padding: 0;
                        height: 40px;
                        background: #f2f3fa;
                        font-weight: normal;
                        .active {
                            color: #2a67ff;
                            font-weight: 500;
                        }
                        .cell {
                            height: 40px;
                            line-height: 40px;
                            padding: 0;
                            .sort-caret {
                                border-width: 4px;
                            }
                            .ascending {
                                top: 7px !important;
                            }
                            .descending {
                                bottom: 9px !important;
                            }
                        }
                    }
                    .descending .sort-caret.descending {
                        border-top-color: #2a67ff;
                    }
                    .ascending .sort-caret.ascending {
                        border-bottom-color: #2a67ff;
                    }
                    th::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 10px;
                        width: 0;
                        height: 20px;
                        border-left: 1px solid #ced1e9;
                    }
                }
            }
        }
        .sort-table::before {
            display: none;
        }
        .course-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .course-list > i {
            width: 280px;
            padding: 10px;
        }
        .no-course {
            width: 374px;
            margin: 150px auto;
            font-size: 14px;
            color: #666;
            text-align: center;
            img {
                width: 374px;
                height: auto;
            }
        }
    }
}
</style>
